import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    isLoggedIn: false,
    name: null,
    email: null,
    loginError: null,
    jwtToken: null,
    refreshToken: null,
    role: null,
    fileManager: {
      isLoggedIn: false,
      user: null,
      password: null,
      host: null,
      port: null,
      connectionId: null
    }
  },
  getters: {
    loggedIn: state => state.isLoggedIn,
    getName: state => state.name,
    getEmail: state => state.email,
    getRole: state => state.role,
    getToken: state => state.jwtToken,
    getRefreshToken: state => state.refreshToken,
    getFileManager: state => state.fileManager,
    getConnectionId: state => state.fileManager.connectionId
  },
  mutations: {
    loginStop: (state, payload) => {
      state.isLoggedIn = !payload.errorMessage
      state.loginError = payload.errorMessage
      state.jwtToken = payload.token
      state.refreshToken = payload.refreshToken
      state.name = payload.name
      state.email = payload.email
      state.role = payload.role? payload.role: 'user'
    },
    logout: (state) => {
      state.isLoggedIn = false
      state.loginError = null
      state.jwtToken = null
      state.refreshToken = null
      state.name = null
      state.email = null
      state.role = null
    },
    clearError: (state) => {
      state.loginError = null
    },
    clearConnectionId: (state) => {
      state.fileManager.connectionId = null
    },
    loginFtp: (state, payload) => {
      state.fileManager.isLoggedIn = payload.isLoggedIn
      state.fileManager.user = payload.user
      state.fileManager.password = payload.password
      state.fileManager.host = payload.host
      state.fileManager.port = payload.port
      state.fileManager.connectionId = payload.connectionId
    },
    updateToken: (state, payload) => {
      state.jwtToken = payload.token
    }
  },
  actions: {
    doLogin ({ commit }, loginData) {
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/auth/signin`, {
        email: loginData.email || '',
        password: loginData.password || ''
      })
        .then((response) => {
          commit('loginStop', {
            errorMessage: null,
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            name: response.data.name,
            role: response.data.role,
            email: response.data.email
          })
        })
        .catch(error => {
          Vue.toasted.error('login error, check your credentials')
          commit('loginStop', {
            errorMessage: error.response.data.error ? error.response.data.error : 'login error',
            token: null,
            refreshToken: null,
            name: null,
            role: null
          })
        })
    },
    doLoginOauthUser({ commit }, loginData){
      commit('loginStop', {
        errorMessage: null,
        token: loginData.token,
        refreshToken: loginData.refreshToken,
        name: loginData.name,
        role: loginData.role,
        email: loginData.email
      })
    },
    getNewToken ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/auth/refresh`, {
          refreshToken: payload.refreshToken,
          email: payload.email
        })
          .then(response => {
            commit('updateToken', {
              token: response.data.token
            })
            resolve(response.data.token)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    doLogout ({ commit }) {
      commit('logout')
    },
    doDismissError ({commit}) {
      commit('clearError')
    },
    doLoginFtp ({ commit }, ftpData) {
      commit('loginFtp', {
        isLoggedIn: true,
        user: ftpData.user,
        password: ftpData.password,
        host: ftpData.host,
        port: ftpData.port,
        connectionId: ftpData.connectionId
      })
    },
    doClearConnectionId ({ commit }) {
      commit('clearConnectionId')
    },
    doLogoutFtp ({ commit }) {
      commit('loginFtp', {
        isLoggedIn: false,
        user: null,
        password: null,
        host: null,
        connectionId: null
      })
    },
    doUpdateToken ({ commit }, token) {
      commit('updateToken', {
        token: token
      })
    }
  }
})
