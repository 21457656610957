<template>
  <div class="flex justify-center items-center">
    <div class="flex flex-col bg-bluewindowludus p-4 rounded w-1/2">
      <div class="w-full text-white font-bold text-2xl text-center mb-2">API Clients</div>
      <div class="flex flex-col divide-y-2 divide-white">
        <div class="flex flex-row text-white text-lg font-bold text-center items-center">
          <div class="w-1/2">Name</div>
          <div class="w-1/2">Actions</div>
        </div>

        <div class="flex flex-col" v-for="apiClient in apiClients" :key="apiClient.id">
          <div class="flex flex-row text-base text-center w-full items-center py-1 text-white">
            <div class="text-center w-1/2">{{apiClient.name}}</div>

            <div class="flex text-center text-sm w-1/2 justify-center items-center">
              <button type="submit" class="rounded-lg bg-graybuttonludus text-white px-1 py-1 mr-2" @click.prevent="toggleDisplayKey(apiClient.key)">Display Key</button>
              <button type="submit" class="rounded-lg bg-yellowbuttonludus text-white px-1 py-1 mr-2" @click.prevent="cycleClientKey(apiClient.name)">Cycle Key</button>
              <button type="submit" class="rounded-lg bg-redbuttonludus text-white px-1 py-1" @click.prevent="deleteClient(apiClient.name)">Delete Client</button>
            </div>

          </div>
        </div>
      </div>

      <div class="flex flex-row justify-center items-center mt-2">
        <modal :width="'w-1/3'" v-if="displayKeyModal">
          <div class="w-full flex flex-row items-center">
            <div class="w-full text-center text-white font-bold"></div>
            <button type="button" class="bg-graybuttonludus text-white px-3 py-1 text-right text-lg font-bold rounded" @click="toggleDisplayKey()">x</button>
          </div>
          <div class="w-full flex flex-row break-all justify-around items-center mt-4 text-white">
            {{displayKey}}
          </div>
        </modal>

        <button class="bg-graybuttonludus px-2 py-1 text-white rounded text-base" @click="toggleNewApiClientModal">New API Client</button>
        <modal :width="'w-1/3'" v-if="newClientModal">
          <div class="w-full flex flex-row items-center">
            <div class="w-full text-center text-white font-bold"> New API CLient</div>
            <button type="button" class="bg-graybuttonludus text-white px-3 py-1 text-right text-lg font-bold rounded" @click="toggleNewApiClientModal">x</button>
          </div>
          <div class="flex flex-row justify-around items-center mt-4">
            <div class="flex flex-col w-1/4">
              <label class="text-white text-base" for="name">Client Name</label>
              <input class="border-none outline-none text-sm" type="text" id="name" v-model="newApiClientName" name="name" required="true">
            </div>
          </div>
          <div class="flex justify-center mt-4 items-center">
            <button class="bg-greenbuttonludus px-2 py-1 text-white rounded-lg text-base" @click="createNewApiClient(newApiClientName)">Create</button>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import {mapGetters} from "vuex";
  import Modal from '../components/partials/Modal'

  export default {
    name: "ApiClients",
    components:{
      Modal
    },
    data() {
      return {
        apiClients: [],
        newClientModal: false,
        displayKeyModal: false,
        displayKey: '',
        newApiClientName: ''
      }
    },
    methods: {
      toggleNewApiClientModal(){
        this.newClientModal = !this.newClientModal
      },
      toggleDisplayKey(key){
        if(this.displayKey){
          this.displayKey = ''
          this.displayKeyModal = false
        }else{
          this.displayKey = key
          this.displayKeyModal = true
        }
      },
      fetchApiClients(){
        axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/api-clients`,
          {
            headers: {
              'x-access-token': this.getToken
            }
          })
          .then(response => {
            this.apiClients = response.data.apiClients
          })

      },
      createNewApiClient(newApiClientName){
        axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/api-clients`,
          {
            name: newApiClientName,
          },
          {
            headers: {
              'x-access-token': this.getToken
            }
          })
          .then(response => {
            if (response.status === 201) {
              this.newApiClientName = ''
              this.fetchApiClients()
              this.toggleNewApiClientModal()
            }
          })

      },
      cycleClientKey(clientName){
        if (confirm(`Do you want to cycle ${clientName}'s key? This will invalidate the current key`)) {
          axios.put(`${process.env.VUE_APP_BACKEND_ENDPOINT}/api-clients`,
            {
              name: clientName,
            },
            {
              headers: {
                'x-access-token': this.getToken
              }
            })
            .then(() => {
              this.newApiClientName = ''
              this.fetchApiClients()
              this.$toasted.success('Key successfully cycled.')
            })
        }
      },
      deleteClient(clientName){
        if (confirm(`Do you really want to delete ${clientName} ?`)) {
          axios.delete(`${process.env.VUE_APP_BACKEND_ENDPOINT}/api-clients/${clientName}`,
            {
              headers: {
                'x-access-token': this.getToken
              }
            })
            .then(() => {
              this.$toasted.success('Client deleted successfully')
              this.fetchApiClients()
            })
        }
      }

    },
    computed: {
      ...mapGetters([
        'getToken'
      ])
    },
    mounted() {
      this.fetchApiClients()
    }
  }
</script>

<style scoped>

</style>