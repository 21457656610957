import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home'
import ListUsers from '../views/ListUsers'
import Guide from '../views/Guide'
import Routers from '../views/Routers'
import Tunnels from '../views/Tunnels'
import SshClient from '../views/SshClient'
import Logs from '../views/Logs'
import FileManager from '../views/FileManager'
import { OauthCallback } from "@ludus-npm/vue-auth"
import ApiClients from "../views/ApiClients";

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/oauth/callback',
      name: 'OauthCallback',
      component: OauthCallback
    },
    {
      path: '/users',
      name: 'Users',
      component: ListUsers
    },
    {
      path: '/guide',
      name: 'Guide',
      component: Guide
    },
    {
      path: '/routers',
      name: 'Routers',
      component: Routers
    },
    {
      path: '/tunnels',
      name: 'Tunnels',
      component: Tunnels
    },
    {
      path: '/ssh/:destination',
      name: 'SshClient',
      component: SshClient,
      props: true
    },
    {
      path: '/logs',
      name: 'Logs',
      component: Logs
    },
    {
      path: '/manager/:targetHost?/:targetPort?',
      name: 'manager',
      component: FileManager,
      props: true
    },
    {
      path: '/api-clients',
      name: 'api-clients',
      component: ApiClients
    }
  ]
})
