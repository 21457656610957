<template>
  <div class="flex flex-col">
    <div class="text-2xl text-white font-bold text-center">Router Setup</div>
    <div class="text-lg text-white font-bold">Router Configuration</div>
    <div class="flex flex-col text-white text-base justify-start items-start">
      <li>Connect to the router ssid</li>
      <li>Access <code><a class="font-bold" href="http://192.168.8.1">192.168.8.1</a></code></li>
      <li>Setup the router password</li>
    </div>
    <div class="text-lg text-white font-bold" >System Configuration</div>
    <div class="flex flex-col text-white text-base">
      <li>Ssh to your router, run
        <code class="font-bold">
          {{ sshRouterCommand}}
        </code>
        <popper
          trigger="clickToOpen"
          :options="{
            placement: 'top',
            modifiers: { offset: { offset: '0,10px' } }
          }"
        >
          <div class="popper">
            Copied!
          </div>
          <button type="button" class="bg-graybuttonludus px-1 rounded-lg" slot="reference"
            v-clipboard:copy="sshRouterCommand">
            <font-awesome-icon class="alt icon" icon="copy"/>
          </button>
        </popper>
      </li>
      <li>Execute the sequence of commands
        <code class="font-bold">
          {{installCommand}}
        </code>
        <popper trigger="clickToOpen" :options="{
            placement: 'top',
            modifiers: { offset: { offset: '0,10px' } }
        }">
          <div class="popper">
            Copied!
          </div>
          <button class="bg-graybuttonludus px-1 rounded-lg" slot="reference"
                  v-clipboard:copy="installCommand">
            <font-awesome-icon class="alt icon" icon="copy"/>
          </button>
        </popper>
      <li>Soon your router will be connected to the system</li>
    </div>
  </div>
</template>

<script>
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
export default {
  name: 'RouterSetup',
  data () {
    return {
      sshRouterCommand: 'ssh root@192.168.8.1',
      installCommand: 'wget ' + this.host() + '/routerFiles.zip &&\n' +
        '          unzip -o routerFiles.zip &&\n' +
        '          rm routerFiles.zip &&\n' +
        '          chmod +x install.sh &&\n' +
        '          ./install.sh &&\n' +
        '          sync'
      // host: process.env.BACKEND_ENDPOINT
    }
  },
  methods: {
    host () {
      //return process.env.VUE_APP_BACKEND_ENDPOINT
      return 'http://server.remote-assistant.luduscristaltec.com:3000'
    }
  },
  components: {
    popper: Popper
  }
}
</script>

<style scoped>
  .tooltip {
    display: block !important;
    z-index: 10000;
  }

  .tooltip .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  .tooltip[x-placement^="top"] {
    margin-bottom: 5px;
  }

  .tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="bottom"] {
    margin-top: 5px;
  }

  .tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="right"] {
    margin-left: 5px;
  }

  .tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip[x-placement^="left"] {
    margin-right: 5px;
  }

  .tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip.popover .popover-inner {
    background: #f9f9f9;
    color: black;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, .1);
  }

  .tooltip.popover .popover-arrow {
    border-color: #f9f9f9;
  }

  .tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  .tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
</style>
