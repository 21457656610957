// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import App from './App'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEyeSlash, faEye, faCopy, faToggleOn, faToggleOff, faArrowUp, faArrowDown, faSync, faUndo, faBan, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from './router'
import { store } from './store'
import VuePaginate from 'vue-paginate'
import * as VueMenu from '@hscmap/vue-menu'
import interceptorsSetup from './helpers/interceptors'
import './assets/tailwind.css'
import { DateTime } from "luxon";
import Toasted from '@gitlab/vue-toasted'

library.add(faEyeSlash, faEye, faCopy, faToggleOn, faToggleOff, faArrowUp, faArrowDown, faSync, faUndo, faBan, faPlusCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)

const toastedConfig = {
  position: 'top-center',
  duration: 3000,
  keepOnHover: true,
}

Vue.use(VueClipboard)
Vue.use(VuePaginate)
Vue.use(VueMenu)
Vue.use(Toasted, toastedConfig)
Vue.filter('formatDate', function(date) {
  return DateTime.fromISO(date).toFormat('D HH:mm:ss')
})

Vue.directive('focus', {
  inserted: function (el) {
    Vue.nextTick(function () {
      el.focus()
    })
  }
})

Vue.config.productionTip = false
interceptorsSetup()
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
