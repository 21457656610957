<template>
  <div class="flex flex-col">
    <div class="text-2xl text-white font-bold text-center">Usage</div>
    <div class="flex flex-col text-white text-base">
      <li>After the router setup, the router mac will be present in the router page with every machine that is connected to it</li>
      <li>Press the Create Connection Button to open a tunnel to the specified machine</li>
      <li>Wait until the connection status column displays <code>connected</code></li>
      <li>Ssh into the server IP and displayed port with the machine user i.e.
        <code class="font-bold">{{sshCommand}}</code>
        <popper
          trigger="clickToOpen"
          :options="{
            placement: 'top',
            modifiers: { offset: { offset: '0,10px' } }
          }"
        >
          <div class="popper">
            Copied!
          </div>
          <button class="bg-graybuttonludus px-1 rounded-lg" slot="reference"
                  v-clipboard:copy="sshCommand">
            <font-awesome-icon class="alt icon" icon="copy"/>
          </button>
        </popper>
        or launch the SSH web terminal
      </li>
      <li>Enter the password associated with the user used in the ssh command</li>
      <li>You are in!</li>
    </div>
  </div>
</template>

<script>
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
export default {
  name: 'Usage',
  data () {
    return {
      sshCommand: 'ssh -p 8000 root@192.168.0.1'
    }
  },
  components: {
    'popper': Popper
  }
}
</script>

<style scoped>

</style>
