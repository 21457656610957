<template>
  <div class="flex justify-center items-center">
      <div class="flex flex-col bg-bluewindowludus text-white p-4 rounded" v-if="!getFileManager.isLoggedIn">
          <h1 class="text-center">File Manager</h1>
          <form class="flex flex-col rounded px-8 pt-2 pb-4 max-w-xs justify-between" @submit.prevent="connect">

            <div class="flex flex-row justify-between items-center mb-2">
              <div class="mr-2">
                <label class="block text-white text-sm font-bold mb-2">Host</label>
                <input type="text" v-model="host" name="host"
                       class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none text-sm"
                >
              </div>
              <div>
                <label class="block text-white text-sm font-bold mb-2">Port</label>
                <input type="text" v-model="port" name="port"
                       class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none text-sm"
                >
              </div>
            </div>

            <div class="mb-2">
              <label class="block text-white text-sm font-bold mb-2">User</label>
              <input type="text" v-model="user" name="email"
                     class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none text-sm"
              >
            </div>

            <div class="form-group">
              <label class="block text-white text-sm font-bold mb-2">Password</label>
              <input type="password" v-model="password" name="password"
                     class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none text-sm"
              >
            </div>

            <button type="submit" class="bg-greenbuttonludus px-2 py-1 text-white rounded text-base text-center">Enter</button>
          </form>
      </div>
      <div class="flex flex-col bg-bluewindowludus text-white p-4 rounded" v-else-if="isLoading">
        <img :src="require('@/assets/loading.gif')" alt="Loading Icon">
      </div>
      <div class="flex flex-col bg-bluewindowludus text-white p-4 rounded w-full" @click="clearSelectedItems" v-else>
        <hsc-menu-style-metal>
          <hsc-menu-context-menu  style="width: 100%" :menuZIndex="3">
        {{error}}
          <div class="flex flex-row justify-between items-center">
            <div class="flex justify-between items-center">
              <input class="text-gray-700 mr-2" @blur="listDirectory(path)" @keyup.enter="$event.target.blur" :value="path">
              <button @click="backDirectory" class="rounded bg-graybuttonludus px-2 py-1 mr-2 ">
                <font-awesome-icon class="alt icon" icon="undo"/>
              </button>
              <button @click="listDirectory(path)" class="rounded bg-graybuttonludus px-2 py-1">
                <font-awesome-icon class="alt icon" icon="sync"/>
              </button>
            </div>

            <div class="flex justify-between items-center">
              <button @click="toggleConsole" class="rounded bg-graybuttonludus text-sm px-2 py-1 mr-1" >Toggle Console</button>
              <button @click="disconnect" class="rounded bg-redbuttonludus text-sm px-2 py-1 mr-1" >Disconnect</button>
              <button @click="reconnect" class="rounded bg-yellowbuttonludus text-sm px-2 py-1">Reconnect</button>
            </div>
          </div>
        <div class="flex flex-wrap justify-start overflow-y-auto max-h-50vh">
          <div class="flex flex-col w-20 m-2 justify-center" @dblclick="backDirectory" v-if="path !=='/'" >
              <img class="opacity-50" src="@/assets/foldericon.png">
              <div class="text-center" title="parent directory">
                <p>..</p>
              </div>
          </div>
          <file-properties :item="activeItem" :path="path" @closeModal="toggleShowProperties" v-if="showFileProperties" @click.native.stop></file-properties>
          <checksum-display :checksum-type="checksumType" :checksum-file="checksumFile" :checksum-value="checksumValue" @closeModal="closeChecksum" v-if="checksumType" @click.native.stop></checksum-display>
          <file-reader v-if="content && readerType" :item="activeItem" :type="readerType" :content="content" @close="closeFileReader" @click.native.stop></file-reader>
          <file-uploader v-if="fileUploaderForm" :type="fileUploaderFormType" :destination-path="path" @close="closeFileUploader" @click.native.stop></file-uploader>
          <file-mover v-if="fileMoverForm" :original-path="path" :selected-files="selected" @close="closeFileMover" @click.native.stop></file-mover>
          <file-compressor v-if="fileCompressorForm" :files="selected" :path="path" @close="closeFileCompressor" @click.native.stop></file-compressor>
          <div class="m-2" @dblclick="itemClicked(item)" v-for="(item, index) in directoryList" :key="index">
            <hsc-menu-style-metal>

                <hsc-menu-context-menu class="w-20" @contextmenu.stop.native :menuZIndex="3">
                  <div class="flex flex-col justify-center" :class="{ 'bg-bluenavbarludus': item.isSelected }" @contextmenu="selectItem(item, $event)"  @click.stop="selectItem(item, $event)" >
                    <img :src="isDirectory(item) ? require('@/assets/foldericon.png') : require('@/assets/fileicon.png')">
                    <div class="text-center" :title="item.name">
                      <input class="text-black" v-if="item.editing === true" v-model="item.newName" @blur="renameFile(item)" @keyup.enter="$event.target.blur" type="text" v-focus>
                      <div v-else class="truncate ">{{item.name}}</div>
                    </div>
                  </div>

                  <template  slot="contextmenu">
                    <hsc-menu-item class="text-left" label="&#128269; Open" v-if="isDirectory(item) && !multipleSelected" @click.native.stop="itemClicked(item)"/>
                    <hsc-menu-item class="text-left" label="&#128229; Download" v-if="!hasDirectorySelected" @click.native.stop="getFiles"/>
                    <hsc-menu-item class="text-left" label="&#128209; View As Text" v-if="!multipleSelected && !isDirectory(item)" @click.native.stop="openItem(item)"/>
                    <hsc-menu-item class="text-left" label="&#128443; View As Image" v-if="!multipleSelected && !isDirectory(item)" @click.native.stop="openImage(item)"/>
                    <hsc-menu-item class="text-left" label="&#10006; Delete" @click.native.stop="deleteFiles"/>
                    <hsc-menu-item class="text-left" label="&#127991; Rename" v-if="!multipleSelected" @click.native.stop="changeName(item)"/>
                    <hsc-menu-item class="text-left" label="&#128259; Move" @click.native.stop="openFileMover"/>
                    <hsc-menu-item class="text-left" label="Copy Path" v-if="!multipleSelected" v-clipboard:copy="path + item.name" @click.native.stop/>
                    <hsc-menu-item class="text-left" label="Checksums" v-if="!multipleSelected && !hasDirectorySelected">
                      <hsc-menu-item label="SHA1" @click.native.stop="getSha1(item)"/>
                      <hsc-menu-item label="MD5" @click.native.stop="getMd5(item)"/>
                    </hsc-menu-item>
                    <hsc-menu-item class="text-left" label="&#128230;Compress" @click.native.stop="openFileCompressor"/>
                    <hsc-menu-item class="text-left" label="&#128199; Properties" v-if="!multipleSelected" @click.native.stop="toggleShowProperties"/>
                  </template>

                </hsc-menu-context-menu>
            </hsc-menu-style-metal>
          </div>
        </div>
            <template  slot="contextmenu">
              <hsc-menu-item class="text-left" label="&#128269; New File" @click.native.stop="openNewFileUploader()"/>
              <hsc-menu-item class="text-left" label="&#128229; Upload File" @click.native.stop="openFileUploader()"/>
            </template>
          </hsc-menu-context-menu>
        </hsc-menu-style-metal>
      </div>
    <console v-if="consoleEnabled" :destination="host + ':' + port" :user="user" ref="xterm"></console>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import FileProperties from '../components/FileProperties'
import ChecksumDisplay from '../components/ChecksumDisplay'
import FileReader from '../components/FileReader'
import FileUpload from '../components/FileUpload'
import FileMover from '../components/FileMover'
import FileCompress from '../components/FileCompress'
import Console from '../components/Console'

export default {
  name: 'FileManager',
  props: {
    targetHost: {
      type: String,
      default: ''
    },
    targetPort: {
      default: ''
    }
  },
  data () {
    return {
      user: '',
      password: '',
      host: this.targetHost,
      port: this.targetPort,
      path: '/',
      directoryList: [],
      selected: [],
      error: '',
      renameModal: false,
      showFileProperties: false,
      checksumType: '',
      checksumValue: '',
      checksumFile: '',
      content: '',
      readerType: '',
      fileUploaderForm: false,
      fileUploaderFormType: '',
      isLoading: null,
      fileMoverForm: null,
      fileCompressorForm: null,
      consoleEnabled: false
    }
  },
  components: {
    'file-properties': FileProperties,
    'checksum-display': ChecksumDisplay,
    'file-reader': FileReader,
    'file-uploader': FileUpload,
    'file-mover': FileMover,
    'file-compressor': FileCompress,
    'console': Console
  },
  methods: {
    ...mapActions([
      'doLoginFtp',
      'doLogoutFtp',
      'doClearConnectionId'
    ]),
    ...mapGetters([
      'getConnectionId'
    ]),
    clearError () {
      this.error = ''
    },
    connect () {
      this.clearError()
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/login`,
        {
          user: this.user,
          password: this.password,
          host: this.host,
          port: this.port
        })
        .then(response => {
          if (response.status === 200) {
            this.doLoginFtp({
              user: this.user,
              password: this.password,
              host: this.host,
              port: this.port,
              connectionId: response.data.connectionId
            })
            this.path = '/home/' + this.user + '/'
            this.listDirectory(this.path)
          }
        })
        .catch(error => {
          console.log(error)
          this.error = error
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    reconnect () {
      this.clearError()
      this.doClearConnectionId()
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/login`,
        {
          user: this.getFileManager.user,
          password: this.getFileManager.password,
          host: this.getFileManager.host,
          port: this.getFileManager.port
        })
        .then(response => {
          if (response.status === 200) {
            this.doLoginFtp({
              user: this.getFileManager.user,
              password: this.getFileManager.password,
              host: this.getFileManager.host,
              port: this.getFileManager.port,
              connectionId: response.data.connectionId
            })
            this.listDirectory(this.path)
          }
        })
        .catch(error => {
          console.log(error)
          this.error = error
        })
    },
    listDirectory (path) {
      this.clearError()
      this.isLoading = true
      this.directoryList = []
      this.clearSelectedItems()
      if (this.path[this.path.length - 1] !== '/') {
        this.path += '/'
      }

      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/directory`,
        {
          fullPath: path
        },
        {
          headers: {
            'connection-id': this.getConnectionId()
          }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.error) {
              this.error = response.data.error
            } else {
              response.data.forEach((element) => {
                element.isSelected = false
              })
              this.directoryList = response.data
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getFile (file, directoryPath) {
      this.clearError()
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/getFile`, {
        filename: file.name,
        path: directoryPath
      },
      {
        headers: {
          'connection-id': this.getConnectionId()
        },
        responseType: 'blob'
      })
        .then(response => {
          this.downloadFile(file.name, response.data)
        })
        .catch(error => {
          console.log(error)
          this.error = '' + error
        })
    },
    downloadFile (fileName, fileData) {
      let fileURL = window.URL.createObjectURL(new Blob([fileData]))
      let fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.download = fileName
      document.body.appendChild(fileLink)

      fileLink.click()
      document.body.removeChild(fileLink)
    },
    getFiles () {
      this.selected.forEach((item) => {
        return this.getFile(item, this.path)
      })
    },
    deleteFile (item) {
      this.clearError()
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/deleteFile`, {
        filePath: this.path + item.name
      },
      {
        headers: {
          'connection-id': this.getConnectionId()
        }
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data.error) {
              this.error = response.data.error
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    deleteDir (item) {
      this.clearError()
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/deleteDir`, {
        dirPath: this.path + item.name
      },
      {
        headers: {
          'connection-id': this.getConnectionId()
        }
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data.error) {
              this.error = response.data.error
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    deleteFiles () {
      if (confirm('Do you really want to delete ' + this.selected.map(a => a.name).join(', ') + '?')) {
        this.selected.forEach((item) => {
          if (item.type === 'd') {
            this.deleteDir(item)
          } else {
            this.deleteFile(item)
          }
        })
      }

      if (!this.error) {
        this.listDirectory(this.path)
      }
    },
    changeName (item) {
      item.editing = true
      item.newName = item.name
      this.$forceUpdate()
    },
    renameFile (item) {
      this.clearError()
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/renameFile`, {
        oldName: item.name,
        newName: item.newName,
        path: this.path
      },
      {
        headers: {
          'connection-id': this.getConnectionId()
        }
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data.error) {
              this.error = response.data.error
            } else {
              this.listDirectory(this.path)
            }
            item.editing = false
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getSha1 (item) {
      this.clearError()
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/sha1`, {
        filename: item.name,
        path: this.path
      },
      {
        headers: {
          'connection-id': this.getConnectionId()
        }
      }).then(response => {
        if (response.status === 200) {
          if (response.data.error) {
            this.error = response.data.error
          } else {
            this.checksumType = 'SHA1'
            this.checksumValue = response.data.checksum
            this.checksumFile = item
          }
        }
      })
    },
    getMd5 (item) {
      this.clearError()
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/md5`, {
        filename: item.name,
        path: this.path
      },
      {
        headers: {
          'connection-id': this.getConnectionId()
        }
      }).then(response => {
        if (response.status === 200) {
          if (response.data.error) {
            this.error = response.data.error
          } else {
            this.checksumType = 'MD5'
            this.checksumValue = response.data.checksum
            this.checksumFile = item
          }
        }
      })
    },
    closeChecksum () {
      this.checksumType = ''
      this.checksumValue = ''
      this.checksumFile = {}
    },
    toggleShowProperties () {
      this.showFileProperties = !this.showFileProperties
    },
    disconnect () {
      this.clearError()
      this.consoleEnabled = false
      axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/logout`,
        {
          headers: {
            'connection-id': this.getConnectionId()
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.doLogoutFtp()
            this.path = ''
            this.directoryList = []
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.doLogoutFtp()
            this.path = ''
            this.directoryList = []
          }

          console.log(error)
        })
    },
    isDirectory (item) {
      return item.type === 'd'
    },
    backDirectory () {
      let split = this.path.split('/')
      this.path = split.slice(0, split.length - 2).join('/') + '/'
      this.listDirectory(this.path)
    },
    itemClicked (item) {
      if (this.isDirectory(item)) {
        this.path = this.path + item.name + '/'
        this.listDirectory(this.path)
      }
    },
    clearSelectedItems () {
      this.directoryList.forEach((element) => {
        element.isSelected = false
      })
      this.selected = []
    },
    selectItem (item, $event) {
      if ($event.ctrlKey) {
        this.multipleSelect(item, $event)
      } else {
        if ($event.which === 3 && this.selected.length > 0 && this.selected.indexOf(this.findFileInSelected(item)[0]) !== -1) {
          return
        }
        this.clearSelectedItems($event)
        item.isSelected = !item.isSelected
        // item.isSelected = true
        if (item.isSelected === true) {
          this.selected.push(item)
        } else {
          const foundElement = this.findFileInSelected(item)
          const itemIndex = this.selected.indexOf(foundElement[0])
          if (itemIndex > -1) {
            this.selected.splice(itemIndex, 1)
          }
        }
      }
    },
    multipleSelect (item, $event) {
      if ($event.which !== 3) {
        item.isSelected = !item.isSelected
      } else {
        item.isSelected = true
      }

      const foundElement = this.findFileInSelected(item)
      if (item.isSelected === true && foundElement.length === 0) {
        this.selected.push(item)
      } else if ($event.which !== 3) {
        const itemIndex = this.selected.indexOf(foundElement[0])
        if (itemIndex > -1) {
          this.selected.splice(itemIndex, 1)
        }
      }
    },
    findFileInSelected (item) {
      return this.selected.filter(object => {
        return object.name === item.name
      })
    },
    openItem (file) {
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/getFile`, {
        filename: file.name,
        path: this.path
      },
      {
        headers: {
          'connection-id': this.getConnectionId()
        }
      }).then(response => {
        this.content = response.data
        this.readerType = 'text'
      })
    },
    closeFileReader () {
      this.content = ''
      this.readerType = ''
    },
    openImage (image) {
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/getImage`, {
        filename: image.name,
        path: this.path
      },
      {
        headers: {
          'connection-id': this.getConnectionId()
        }
      }).then(response => {
        this.content = response.data
        console.log(this.content)
        this.readerType = 'image'
      })
    },
    openFileUploader () {
      this.fileUploaderForm = true
      this.fileUploaderFormType = 'UPLOADFILE'
      // this.listDirectory(this.path)
    },
    openNewFileUploader () {
      this.fileUploaderForm = true
      this.fileUploaderFormType = 'NEWFILE'
    },
    closeFileUploader (action) {
      this.fileUploaderForm = false
      this.fileUploaderFormType = ''
      if (action === 'refresh') {
        this.listDirectory(this.path)
      }
    },
    openFileMover () {
      this.fileMoverForm = true
    },
    closeFileMover () {
      this.fileMoverForm = false
      this.listDirectory(this.path)
    },
    openFileCompressor () {
      this.fileCompressorForm = true
    },
    closeFileCompressor () {
      this.fileCompressorForm = false
      this.listDirectory(this.path)
    },
    toggleConsole () {
      this.consoleEnabled = !this.consoleEnabled
    }
  },
  computed: {
    ...mapGetters([
      'getFileManager'
    ]),
    activeItem () {
      return this.selected[0] ? this.selected[0] : null
    },
    multipleSelected () {
      if (this.selected.length > 1) {
        return true
      } else {
        return false
      }
    },
    hasDirectorySelected () {
      let containsDirectory = false
      this.selected.forEach(item => {
        if (item.type === 'd') {
          containsDirectory = true
        }
      })
      return containsDirectory
    }
  },
  mounted () {
  },
  beforeDestroy () {
    if (this.getConnectionId) {
      this.disconnect()
    }
    this.consoleEnabled = false
  }
}
</script>

<style scoped>
  .selected {
    background-color: #2778bf;
    color: white;
  }
</style>
