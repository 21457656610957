<template>
  <modal :width="'w-80vw'">
    <div class="flex flex-col h-90vh">
      <div class="w-full flex flex-row justify-center items-center">
        <div class="font-bold"> {{item.name}}</div>
      </div>
      <div class="text-sm text-left whitespace-pre overflow-y-auto" v-html="content" v-if="type === 'text'">
      </div>
      <img :src="content" v-if="type === 'image'">
      <div class="flex flex-row justify-end mt-2">
        <button class="bg-graybuttonludus px-2 py-1 rounded" @click="closeModal()">
          Close
        </button>
      </div>
    </div>
  </modal>

</template>

<script>
  import Modal from './partials/Modal'
export default {
  name: 'FileReader',
  components:{
    Modal
  },
  props: [
    'item',
    'content',
    'type'
  ],
  methods: {
    closeModal () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
</style>
