<template>
  <div class="flex justify-center items-center">
    <div class="flex flex-col bg-bluewindowludus p-4 rounded w-1/2">
      <div class="w-full text-white font-bold text-2xl text-center mb-2">Tunnel List</div>
    <div class="flex flex-col divide-y-2 divide-white">
      <div class="flex flex-row text-white text-lg font-bold text-center items-center">
        <div class="w-1/6">Name</div>
        <div class="w-1/6">Port</div>
        <div class="w-1/6">Status</div>
        <div class="w-1/6">Actions</div>
        <div class="w-1/6">Info</div>
        <div class="w-1/6">Target/Local</div>
      </div>

      <div class="flex flex-col" v-for="tunnel in tunnels" :key="tunnel.id">
        <div class="flex flex-row text-base text-center w-full items-center py-1 text-white">
          <div class="text-center w-1/6">{{tunnel.name}}</div>
          <div class="text-center w-1/6">{{tunnel.port}}</div>
          <div class="text-center w-1/6">{{tunnel.status}}</div>
          <div class="flex text-center text-sm w-1/6 justify-around items-center">
            <button type="submit" class="rounded-lg bg-redbuttonludus text-white px-1 py-1" @click.prevent="closeTunnel(tunnel)">Close</button>
            <router-link class="rounded-lg bg-teal-600 text-white px-1 py-1" tag="button" :to="{name: 'SshClient', params: { destination: ip + ':' + tunnel.port }}">SSH</router-link>
            <router-link class="rounded-lg bg-bluebuttonludus text-white px-1 py-1" tag="button" :to="{name: 'manager', params: { targetHost: ip,  targetPort: tunnel.port }}">FTP</router-link>
          </div>
          <div class="text-center w-1/6">
            <button class="rounded-lg bg-white text-black px-2 py-1" @click="selectTunnel(tunnel)">&plus;</button>
            <modal :width="'w-1/4'" v-if="pickedTunnel.id === tunnel.id">
              <div class="w-full flex flex-row items-center">
                <div class="w-full text-center text-white font-bold"> Tunnel Info</div>
                <button type="button" class="bg-graybuttonludus text-white px-3 py-1 text-right text-lg font-bold rounded" @click="resetSelectedTunnel">x</button>
              </div>
              <div class="flex flex-col text-white text-left">
                <li class="my-1">Created At: {{tunnel.createdAt}}</li>
                <li class="my-1">Open a terminal in the target machine.</li>
                <li class="my-1">Insert the command:
                  <code class="font-bold">{{remoteCommand(tunnel)}}</code>
                  <popper
                    trigger="clickToOpen"
                    :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '0,10px' } }
                          }"
                  >
                    <div class="popper">
                      Copied!
                    </div>
                    <button class="bg-graybuttonludus rounded-lg px-1" slot="reference"
                            v-clipboard:copy="remoteCommand(tunnel)">
                      <font-awesome-icon class="alt icon" icon="copy"/>
                    </button>
                  </popper>
                  .
                </li>
                <li class="my-1">Use the password
                  <code class="font-bold">gQS5PW63wr</code>
                  <popper
                    trigger="clickToOpen"
                    :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '0,10px' } }
                          }"
                  >
                    <div class="popper">
                      Copied!
                    </div>
                    <button class="bg-graybuttonludus rounded-lg px-1" slot="reference"
                            v-clipboard:copy="'gQS5PW63wr'">
                      <font-awesome-icon class="alt icon" icon="copy"/>
                    </button>
                  </popper>
                  for the
                  <code>usertunnelssh</code>
                  user.
                </li>
                <li class="my-1">From your machine execute
                  <code class="font-bold">{{localCommand(tunnel)}}</code>
                  <popper
                    trigger="clickToOpen"
                    :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '0,10px' } }
                          }"
                  >
                    <div class="popper">
                      Copied!
                    </div>
                    <button class="bg-graybuttonludus rounded-lg px-1" slot="reference"
                            v-clipboard:copy="localCommand(tunnel)">
                      <font-awesome-icon class="alt icon" icon="copy"/>
                    </button>
                  </popper>
                  where <code class="font-bold">root</code> is the username in the target machine.</li>
                <li class="my-1">Use the password of root.</li>
              </div>
            </modal>
          </div>
          <div class="text-center w-1/6">
            <popper
              trigger="clickToOpen"
              :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '0,10px' } }
                          }"
            >
              <div class="popper">
                Copied!
              </div>
              <button class="bg-graybuttonludus py-1 px-2 rounded-lg" slot="reference"
                      v-clipboard:copy="remoteCommand(tunnel)">
                <font-awesome-icon class="alt icon" icon="arrow-up"/>
              </button>
            </popper> |
            <popper
              trigger="clickToOpen"
              :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '0,10px' } }
                          }"
            >
              <div class="popper">
                Copied!
              </div>
              <button class="bg-graybuttonludus py-1 px-2 rounded-lg" slot="reference"
                      v-clipboard:copy="localCommand(tunnel)">
                <font-awesome-icon class="alt icon" icon="arrow-down"/>
              </button>
            </popper>
          </div>
        </div>
      </div>
    </div>

      <div class="flex flex-row justify-center items-center mt-2">

        <button class="bg-graybuttonludus px-2 py-1 text-white rounded text-base" @click="toggleNewTunnelModal">New tunnel</button>
        <modal :width="'w-1/3'" v-if="newTunnelModal">
          <div class="w-full flex flex-row items-center">
            <div class="w-full text-center text-white font-bold"> New tunnel</div>
            <button type="button" class="bg-graybuttonludus text-white px-3 py-1 text-right text-lg font-bold rounded" @click="toggleNewTunnelModal">x</button>
          </div>
          <div class="flex flex-row justify-around items-center mt-4">
            <div class="flex flex-col w-1/4">
              <label class="text-white text-base" for="name">Tunnel Name</label>
              <input class="border-none outline-none text-sm" type="text" id="name" v-model="newTunnelName" name="name" required="true">
            </div>
            <div class="flex flex-col w-1/8">
              <label class="text-white text-base" for="name">Target Port</label>
              <input class="border-none outline-none text-sm" type="text" id="targetPort" v-model="targetPort" name="targetPort" required="true">
            </div>
          </div>
          <div class="flex justify-center mt-4 items-center">
            <button class="bg-greenbuttonludus px-2 py-1 text-white rounded-lg text-base" @click="createTunnel(newTunnelName, targetPort)">Create</button>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import { mapGetters } from 'vuex'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
import Modal from '../components/partials/Modal'

export default {
  name: 'Tunnels',
  components: {
    popper: Popper,
    Modal
  },
  data () {
    return {
      tunnels: [],
      ip: '',
      newTunnelName: '',
      pollingTunnels: {},
      pickedTunnel: {},
      newTunnelModal: false,
      targetPort: 22
    }
  },
  methods: {
    selectTunnel(tunnel) {
      this.pickedTunnel = tunnel
    },
    resetSelectedTunnel(){
      this.pickedTunnel = {}
    },
    toggleNewTunnelModal(){
      this.newTunnelModal = !this.newTunnelModal
    },
    closeTunnel (tunnel) {
      axios.delete(`${process.env.VUE_APP_BACKEND_ENDPOINT}/tunnel/${tunnel.id}`,
        {
          headers: {
            'x-access-token': this.getToken
          }
        })
        .then(response => {
          console.log(response)
          this.fetchTunnelsData()
        })
        .catch(error => {
          console.log(error)
        })
    },
    createTunnel (tunnelName, targetPort) {
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/tunnel`,
        {
          name: tunnelName,
          targetPort: targetPort
        },
        {
          headers: {
            'x-access-token': this.getToken
          }
        })
        .then(response => {
          if (response.status === 201) {
            this.newTunnelName = ''
            this.fetchTunnelsData()
            this.toggleNewTunnelModal()
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchTunnelsData () {
      axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/tunnel`,
        {
          headers: {
            'x-access-token': this.getToken
          }
        })
        .then(response => {
          this.tunnels = response.data.tunnels
          this.ip = response.data.ip
        })
        .catch(error => {
          console.log(error)
        })
    },
    remoteCommand (tunnel) {
      return `ssh -N -R ${tunnel.port}:localhost:${tunnel.targetPort} usertunnelssh@${this.ip}`
    },
    localCommand (tunnel) {
      return `ssh -p ${tunnel.port} root@${this.ip}`
    }
  },
  computed: {
    ...mapGetters([
      'getToken'
    ])
  },
  mounted () {
    this.fetchTunnelsData()
    this.pollingTunnels = setInterval(this.fetchTunnelsData, 5000)
  },
  beforeDestroy () {
    clearInterval(this.pollingTunnels)
  }

}
</script>

<style scoped>

</style>
