<template>
  <div class="flex w-screen h-screen absolute top-0 left-0 bg-black bg-opacity-50 z-50 justify-center items-center">
    <div :class="modalBodyWidth">
      <div class="flex flex-col bg-bluewindowludus rounded justify-between p-4 w-full" >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Modal",
    props:[
      'width'
    ],
    computed: {
      modalBodyWidth() {
        let widthClass = {}
        if(this.width){
          widthClass[this.width] = true
        } else{
          widthClass['w-1/8'] = true
        }
        return widthClass
      }
    }

  }
</script>

<style scoped>

</style>