import axios from 'axios'
import { store } from '../store'
import router from '../router'
import Vue from 'vue'

export default function setup () {
  axios.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.response.status !== 401 && (error.response.status !== 400 && !error.config.url.includes('/auth/refresh'))) {

      if(error.response.data.error){
        Vue.toasted.error(error.response.data.error)
      }

      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    if (error.config.url.includes('/auth/refresh') || error.config.url.includes('/auth/signin') || error.response.data.error === 'invalid token') {
      if(!error.config.url.includes('/auth/signin')){
        console.log('LOGOUT')
        store.dispatch('doLogout')
        router.push({name: 'Home'})
      }

      return new Promise((resolve, reject) => {
        reject(error)
      })
    }
    return store.dispatch('getNewToken', {
      refreshToken: store.getters.getRefreshToken,
      email: store.getters.getEmail
    })
      .then((token) => {
        // store.dispatch('doUpdateToken', token)
        const config = error.config
        config.headers['x-access-token'] = token

        return new Promise((resolve, reject) => {
          axios.request(config)
            .then(response => {
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      })
      .catch((error) => {
        Promise.reject(error)
      })
  })
}
