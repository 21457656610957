<template>
  <div class="flex justify-center items-center">
    <div class="flex flex-col bg-bluewindowludus p-4 rounded w-full">
      <div class="w-full text-white font-bold text-2xl text-center mb-2">Router List</div>
      <router-users v-if="openRouterUsers" :router="selectedRouter" @close="closeRouterUsersModal"/>

      <div class="flex flex-col divide-y-2 divide-white">

        <div class="flex flex-row text-white text-lg font-bold text-center items-center">
          <div :class="calculateColWidth">MAC</div>
          <div :class="calculateColWidth">#Machines</div>
          <div :class="calculateColWidth">Description</div>
          <div :class="calculateColWidth">Status</div>
          <div :class="calculateColWidth">Version</div>
          <div :class="calculateColWidth" v-if="getRole() === 'admin'">Users</div>
          <div :class="calculateColWidth">Info</div>
          <div :class="calculateColWidth" v-if="getRole() === 'admin'">Manage</div>
        </div>



        <div class="flex flex-col" v-for="router in routers" :key="'router' + router.id">
          <div class="flex flex-row text-base text-center w-full items-center py-1" >
            <div class="text-white" :class="calculateColWidth"> {{router.mac}} </div>
            <div class="text-white" :class="calculateColWidth"> {{router.machines.length}} </div>
            <div class="text-white" :class="calculateColWidth"> <input class="bg-transparent border-none text-center" v-model="router.description" @blur="updateRouterDescription(router)" @keyup.enter="$event.target.blur"></div>
            <div :class="calculateColWidth">
              <span class="rounded-full bg-greenbuttonludus text-white px-2" v-if="router.status === true">Online</span>
              <span class="rounded-full bg-redbuttonludus text-white px-2" v-else>Offline</span>
            </div>
            <div class="text-white" :class="calculateColWidth"> {{router.version}}</div>
            <div class="text-white" :class="calculateColWidth" v-if="getRole() === 'admin'">
              {{router.users.join(', ')}}
            </div>
            <div :class="calculateColWidth">
              <button v-if="router.machines.length > 0" class="rounded-lg bg-graybuttonludus text-white px-2" @click="selectRouter(router)">Machines Info {{selectedRouterExpand.id === router.id? '-' : '+'}}</button>
            </div>
            <div :class="calculateColWidth" v-if="getRole() ==='admin'">
              <button class="rounded-lg bg-graybuttonludus text-white px-2" @click="openRouterUsersModal(router)">Manage</button>
            </div>
          </div>
          <div class="w-full" v-if="selectedRouterExpand && router.id === selectedRouterExpand.id && router.status === true" :key="'routerMachines' + router.id">
            <div class="flex flex-col">
              <div class="flex flex-row text-center text-base text-white w-full">
                <div class="w-1/4">IP</div>
                <div class="w-1/4">Action</div>
                <div class="w-1/4">Connection Status</div>
                <div class="w-1/4">Connection At</div>
              </div>
              <div class="flex flex-col overflow-y-auto h-32">
                <div class="flex flex-row text-center text-white items-center text-sm py-1" v-for="machine in router.machines" :key="'machine' + machine.id">
                  <div class="w-1/4">{{machine.ip}}</div>
                  <div class="w-1/4">
                    <div v-if="machine.status === 'reachable' && machine.openPorts" >
                      <button class="rounded-full px-2 text-white bg-greenbuttonludus" v-for="(openPort, index) in machine.openPorts.split(',')" :key="index" @click="openConnection(machine, router, openPort)" >Connect:{{openPort}} </button>
                    </div>
                    <button class="rounded-full px-2 text-white bg-yellowbuttonludus" @click="cancelConnection(machine, router)" v-if="machine.status === 'scheduled'">Cancel Connection</button>
                    <template v-if="machine.status ==='connected'">
                      <router-link class="rounded-full px-2 text-white bg-bluebuttonludus" tag="button" :to="{name: 'SshClient', params: { destination: ip + ':' + machine.port }}">SSH</router-link>
                      <router-link class="rounded-full px-2 text-white bg-graybuttonludus" tag="button" :to="{name: 'manager', params: { targetHost: ip,  targetPort: machine.port }}">FTP</router-link>
                      <button class="rounded-full px-2 text-white bg-redbuttonludus" @click="closeConnection(machine,router)">Close Connection</button>
                    </template>
                  </div>
                  <div class="w-1/4">{{machine.status}}</div>
                  <div class="w-1/4" id="port<%=routers[i].machines[j].port%>">
                    {{machine.port? ip + ':' + machine.port : '---' }}
                    <popper
                      v-if="machine.port"
                      trigger="clickToOpen"
                      :options="{
                      placement: 'top',
                      modifiers: { offset: { offset: '0,10px' } }
                    }"
                    >
                      <div class="popper">
                        Copied!
                      </div>
                      <button class="bg-graybuttonludus px-2 rounded-lg" slot="reference"
                              v-clipboard:copy="ip + ':' + machine.port">
                        <font-awesome-icon class="alt icon" icon="copy"/>
                      </button>
                    </popper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination class="text-white text-base" :currentPage="currentPage" :lastPage="lastPage" @previous-page="previousPage" @next-page="nextPage"></Pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
import axios from 'axios'
import RouterUsers from '../components/RouterUsers'
import { Pagination } from '@ludus-npm/vue-pagination'

export default {
  name: 'Routers',
  data () {
    return {
      routers: [],
      selectedRouter: {},
      selectedRouterExpand:{},
      ip: '',
      openRouterUsers: false,
      pollingRouters: {},
      currentPage: 1,
      lastPage: null,
      pageSize: 15,
    }
  },
  methods: {
    selectRouter(router) {
      if(this.selectedRouterExpand.id === router.id){
        this.selectedRouterExpand = {}
      } else{
        this.selectedRouterExpand = router
      }
    },
    fetchData () {

      let queryParams = '?'
      if(this.currentPage){
        queryParams += `page=${this.currentPage}`
      }
      if(this.pageSize){
        queryParams += `&size=${this.pageSize}`
      }
      axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/router${queryParams}`,
        {
          headers: {
            'x-access-token': this.getToken()
          }
        })
        .then((response) => {
          if (response.status === 200) {
            this.routers = response.data.data
            this.ip = response.data.ip
            this.lastPage = response.data.last_page
            this.currentPage = response.data.current_page
          }
        })
        .catch(() => {
          this.$toasted('error fetching routers')
        })
    },
    nextPage () {
      this.currentPage+=1
      this.fetchData()
    },
    previousPage() {
      this.currentPage-=1
      this.fetchData()
    },
    updateRouterDescription (router) {
      axios.put(`${process.env.VUE_APP_BACKEND_ENDPOINT}/router/description`,
        {
          id: router.id,
          description: router.description
        },
        {
          headers: {
            'x-access-token': this.getToken()
          }
        })
        .then(() => {
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    updateOwner (router, user) {
      axios.put(`${process.env.VUE_APP_BACKEND_ENDPOINT}/router/owner`,
        {
          routerId: router.id,
          userId: user.id
        },
        {
          headers: {
            'x-access-token': this.getToken()
          }
        })
        .then(() => {
          router.routerOwnerName = user.name
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    openConnection (machine, router, targetPort) {
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/connection/store`,
        {
          remoteMachineIp: machine.ip,
          routerMac: router.mac,
          targetPort: targetPort
        },
        {
          headers: {
            'x-access-token': this.getToken()
          }
        })
        .then(() => {
          machine.status = 'scheduled'
        })
        .catch(error => {
          console.log(error)
        })
    },
    cancelConnection (machine, router) {
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/connection/cancel`,
        {
          remoteMachineIp: machine.ip,
          routerMac: router.mac
        },
        {
          headers: {
            'x-access-token': this.getToken()
          }
        })
        .then(() => {
          machine.status = 'reachable'
          machine.port = null
        })
        .catch(error => {
          console.log(error)
        })
    },
    closeConnection (machine, router) {
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/connection/close`,
        {
          remoteMachineIp: machine.ip,
          routerMac: router.mac
        },
        {
          headers: {
            'x-access-token': this.getToken()
          }
        })
        .then(() => {
          machine.status = 'reachable'
        })
        .catch(error => {
          console.log(error)
        })
    },
    openSSH (ip, port) {
      let routeData = this.$router.resolve({path: '/ssh', query: {data: ip + ':' + port}})
      window.open(routeData, '_blank')
    },
    openRouterUsersModal (router) {
      this.selectedRouter = router
      this.openRouterUsers = true
    },
    closeRouterUsersModal () {
      this.selectedRouter = {}
      this.openRouterUsers = false
      this.fetchData()
    },
    ...mapGetters([
      'getRole',
      'getToken'
    ])
  },
  computed: {
    calculateColWidth () {
      if(this.getRole() === 'admin'){
        return {
          'w-1/8': true
        }
      } else{
        return {
          'w-1/6': true
        }
      }
    }
  },
  components: {
    popper: Popper,
    routerUsers: RouterUsers,
    Pagination
  },
  mounted () {
    this.fetchData()
    this.pollingRouters = setInterval(this.fetchData, 10000)
  },
  beforeDestroy () {
    clearInterval(this.pollingRouters)
  }
}
</script>

<style scoped>
</style>
