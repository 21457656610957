<template>

  <div class="flex justify-center items-center ">
    <div class="flex flex-col bg-bluewindowludus p-4 rounded w-1/2">
      <div class="w-full text-white font-bold text-md text-center mb-2">Logs</div>
      <div class="divide-y-2 divide-white overflow-y-auto mb-6" >
        <div class="flex flex-row items-center justify-between m-1" v-for="(log, index) in logs" :key="index">
          <div class="text-white text-sm w-5/6">{{log.message}}</div>
          <div class="text-white text-sm ">{{log.createdAt | formatDate}}</div>
        </div>
      </div>

      <Pagination class="text-white text-md" :currentPage="currentPage" :lastPage="lastPage" @previous-page="previousPage" @next-page="nextPage"></Pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import {Pagination} from '@ludus-npm/vue-pagination'
export default {
  name: 'Logs',
  components: {
    Pagination
  },
  data () {
    return {
      logs: [],
      currentPage: 1,
      lastPage: null,
      pageSize: 15,
      pollingLogs: {}
    }
  },
  methods: {
    fetchData () {
      let queryParams = '?'
      if(this.currentPage){
        queryParams += `page=${this.currentPage}`
      }
      if(this.pageSize){
        queryParams += `&size=${this.pageSize}`
      }
      axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/log${queryParams}`,
        {
          headers: {
            'x-access-token': this.getToken
          }
        })
    .then((response) => {
          this.logs = response.data.data
          this.lastPage = response.data.last_page
          this.currentPage = response.data.current_page
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    nextPage () {
      this.currentPage+=1
      this.fetchData()
    },
    previousPage() {
      this.currentPage-=1
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters([
      'getToken'
    ])
  },
  mounted () {
    this.fetchData()
    this.pollingLogs = setInterval(this.fetchData, 10000)
  },
  beforeDestroy () {
    clearInterval(this.pollingLogs)
  }
}
</script>

<style>
</style>
