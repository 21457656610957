<template>
  <div class="flex justify-center items-center">
    <div class="flex flex-col bg-bluewindowludus p-4 rounded w-1/2 ">
      <div class="w-full text-white font-bold text-md text-center mb-2">User List</div>
      <div class="divide-y-2 divide-white overflow-y-auto mb-6" >
        <div class="flex flex-row justify-between items-center text-white">
          <div class="w-1/4 text-center">Name</div>
          <div class="w-1/4 text-center">Email</div>
          <div class="w-1/4 text-center">Role</div>
          <div class="w-1/4 text-center"></div>
        </div>
        <div class="flex flex-row items-center justify-between divide-x-2 divide-white p-2" v-for="(user, index) in otherUsers" :key="index">
          <div class="text-white text-sm w-1/4 text-center">
            <input class="bg-transparent border-none text-center w-full" @blur="updateUser(user)" @keyup.enter="$event.target.blur" v-model="user.name">
          </div>
          <div class="text-white text-sm w-1/4 text-center">
            <input class="bg-transparent border-none text-center w-full" @blur="updateUser(user)" @keyup.enter="$event.target.blur" v-model="user.email">
          </div>
          <div class="text-white text-sm w-1/4 text-center">{{user.role}}</div>
          <div class="flex flex-wrap text-white justify-around items-center text-sm w-1/4">
            <button class="block sm:inline-block rounded-lg text-white bg-redbuttonludus py-1 px-2 font-bold text-xs sm:mt-1 xl:mt-0" @click.prevent="invalidateUserToken(user)">Reject Token</button>
            <button class="block sm:inline-block rounded-lg text-white bg-redbuttonludus py-1 px-2 font-bold text-xs sm:mt-1 xl:mt-0" @click.prevent="deleteUser(user)">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'ListUsers',
  data () {
    return {
      users: [],
      roles: [],
      selectedUser: {},
      openRoles: false,
    }
  },
  computed: {
    otherUsers() {
      return this.users.filter( user => user.email !== this.getEmail())
    }
  },
  methods: {
    invalidateUserToken (user) {
      if (confirm('Do you want to invalidate ' + user.name + '\'s token? this will force the user to login upon token expiration?')) {
        axios.put(`${process.env.VUE_APP_BACKEND_ENDPOINT}/auth/reject/`,
          {
            email: user.email
          },
          {
            headers: {
              'x-access-token': this.getToken()
            }
          })
          .then((response) => {
            console.log(response.data)
            this.fetchUsers()
          })
          .catch(error => {
            this.$toasted.error(error.response)
          })
      }
    },
    deleteUser (user) {
      if (confirm('Do you really want to delete ' + user.name + ' ?')) {
        axios.delete(`${process.env.VUE_APP_BACKEND_ENDPOINT}/users/${user.id}`,
          {
            headers: {
              'x-access-token': this.getToken()
            }
          })
          .then((response) => {
            console.log(response.data)
            this.fetchUsers()
          })
          .catch(error => {
            this.$toasted.error(error.response)
          })
      }
    },
    updateUser (user) {
      axios.put(`${process.env.VUE_APP_BACKEND_ENDPOINT}/users`,
        {
          userId: user.id,
          name: user.name,
          email: user.email
        },
        {
          headers: {
            'x-access-token': this.getToken()
          }
        })
        .then((response) => {
          console.log(response.data)
        })
        .catch(error => {
          this.$toasted.error(error.response)
        })
    },
    fetchUsers () {
      axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/users`,
        {
          headers: {
            'x-access-token': this.getToken()
          }
        })
        .then((response) => {
          console.log(response.data)
          if (response.status === 200) {
            this.users = response.data.users
          }
        })
        .catch(error => {
          this.$toasted.error(error.response)
        })
    },
    ...mapGetters([
      'getEmail',
      'getToken',
      'getRole'
    ])
  },
  mounted () {
    this.fetchUsers()
  }
}
</script>

<style scoped>
</style>
