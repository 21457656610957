<template>
  <modal>
    <div class="w-full">
      <div class="w-full text-center text-white"> {{router.mac}} {{router.description ? router.description : '---'}}</div>
    </div>
    <div class="overflow-y-auto whitespace-pre">
      <div class="flex flex-col divide-y-2 divide-white">

        <div class="flex flex-row text-white text-lg font-bold text-center items-center">
          <div class="w-1/2">Name</div>
          <div class="w-1/2">Toggle</div>
        </div>

        <div class="flex flex-col">
          <template v-for="(status, key) in usersStatus">
            <div class="flex flex-row text-base text-center w-full items-center py-1" v-for="user in status" :key="user.id">
              <div class="w-1/2 text-white" > {{user.name}} </div>
              <div class="w-1/2" v-if="key==='assigned'">
                <button class="rounded bg-redbuttonludus px-2 py-1 text-white" @click.prevent="removeUserRouter(user)">
                  <font-awesome-icon class="icon alt" icon="ban"></font-awesome-icon>
                </button>
              </div>
              <div class="w-1/2" v-if="key==='free'">
                <button class="rounded bg-greenbuttonludus px-2 py-1 text-white" @click.prevent="addUserRouter(user)">
                  <font-awesome-icon class="icon alt" icon="plus-circle"></font-awesome-icon>
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-around items-center text-base mt-4">
      <button class="rounded bg-redbuttonludus px-2 py-1 text-white" @click.prevent="deleteRouter(router)">
        Delete Router
      </button>
      <button class="float-right rounded bg-greenbuttonludus px-2 py-1 text-white " @click="closeModal()">
        Close
      </button>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Modal from './partials/Modal'

export default {
  name: 'RouterUsers',
  components: {
    Modal
  },
  props: [
    'router'
  ],
  data () {
    return {
      usersStatus: {},
      error: ''
    }
  },
  methods: {
    ...mapGetters([
      'getToken'
    ]),
    closeModal () {
      this.$emit('close')
    },
    cleanError () {
      this.error = ''
    },
    getRouterUsersStatus () {
      this.cleanError()
      axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/router/owner-status/${this.router.id}`, {
        headers: {
          'x-access-token': this.getToken()
        }
      })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data)
            this.usersStatus = response.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    addUserRouter (user) {
      this.cleanError()
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/router/owner-status`, {
        userId: user.id,
        routerId: this.router.id
      },
      {
        headers: {
          'x-access-token': this.getToken()
        }
      })
        .then(response => {
          if (response.status === 201) {
            this.getRouterUsersStatus()
          } else {
            this.error = response.data.error
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    removeUserRouter (user) {
      console.log(user)
      axios.delete(`${process.env.VUE_APP_BACKEND_ENDPOINT}/router/owner-status/${this.router.id}/user/${user.id}`, {
        headers: {
          'x-access-token': this.getToken()
        }
      })
        .then(response => {
          if (response.status === 200) {
            this.getRouterUsersStatus()
          } else {
            this.error = response.data.error
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    deleteRouter (router) {
      if (confirm('Do you really want to delete router ' + router.mac + ' ?')) {
        axios.delete(`${process.env.VUE_APP_BACKEND_ENDPOINT}/router/${router.id}`,
          {
            headers: {
              'x-access-token': this.getToken()
            }
          })
          .then(() => {
            this.closeModal()
          })
          .catch(error => {
            console.log(error.response)
          })
      }
    }
  },
  mounted () {
    this.getRouterUsersStatus()
  }
}
</script>

<style scoped>


  .modal-body {
    text-align: left;
    white-space: pre;
    overflow-y: auto;
  }

  .modal-default-button {
    float: right;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
