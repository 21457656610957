<template>
  <modal :width="'w-1/6'">
    <div class="w-full flex flex-row justify-center items-center">
      <h3 class="font-bold">Compress Files</h3>
    </div>
    <div class="flex flex-col justify-start overflow-y-auto text-base">
      <div v-if="error"> {{error}}</div>
      <div>Compressing: {{filesNamesString}}</div>
      <div>Compressed file: <input class="text-black" v-model="compressedName"/>.tar.gz</div>
    </div>
    <div class="flex flex-row justify-around items-center text-base mt-4">
      <button class="bg-graybuttonludus  px-2 py-1 rounded" @click="$emit('close')">
        Close
      </button>
      <button class="bg-greenbuttonludus  px-2 py-1 rounded" @click="compress">
        Compress
      </button>
    </div>
  </modal>

</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Modal from './partials/Modal'

export default {
  name: 'FileCompress',
  components: {
    Modal
  },
  props: [
    'files',
    'path'
  ],
  data () {
    return {
      compressedName: '',
      error: ''
    }
  },
  computed: {
    filesNamesString () {
      return this.files.map((item) => item.name).join(', ')
    }
  },
  methods: {
    ...mapGetters([
      'getConnectionId'
    ]),
    compress () {
      this.error = ''
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/compress`,
        {
          files: this.files.map((item) => item.name),
          path: this.path,
          zipName: this.compressedName
        },
        {
          headers: {
            'connection-id': this.getConnectionId()
          }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.error) {
              this.error = response.data.error
            } else {
              this.$emit('close')
            }
          }
        })
    }
  }
}
</script>

<style scoped>
</style>
