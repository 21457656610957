<template>
  <div id="footer" class="w-full text-center bg-grayfooterludus text-white p-2">
    © Copyright {{year}} | LudusCristaltec
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
</style>
