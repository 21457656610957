<template>
  <console  :destination="destination" ref="xterm"></console>
</template>
<script>

import Console from '../components/Console'
export default {
  name: 'SshClient',
  components: {
    console: Console
  },
  data () {
    return {
      terminal: {
        name: 'terminal'
      }
    }
  },
  props: {
    destination: {
      type: String,
      default: 'noIp'
    }
  }
}
</script>
<style scoped>

</style>
