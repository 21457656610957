<template>
    <modal :width="'w-1/6'">
      <div class="w-full flex flex-row justify-center items-center">
         <div class="font-bold">Properties for {{item.name}}</div>
      </div>

      <div class="flex flex-col dividy-y-2 divide-white text-base">
          <div class="flex flex-row justify-between items-center">
            <div>Name:</div><div>{{item.name}}</div>
          </div>
          <div class="flex flex-row justify-between items-center">
            <div>Path:</div><div>{{path}}</div>
          </div>
          <div class="flex flex-row justify-between items-center">
            <div>OwnerId:</div><div>{{item.owner}}</div>
          </div>
          <div class="flex flex-row justify-between items-center">
            <div>GroupId:</div><div>{{item.group}}</div>
          </div>
          <div class="flex flex-row justify-between items-center">
            <div>Size:</div><div>{{parseFloat(item.size/1024).toFixed(2)}}KB</div>
          </div>
          <div class="flex flex-row justify-between items-center">
            <div>Last Access:</div><div>{{formatDate(item.accessTime)}}</div>
          </div>
          <div class="flex flex-row justify-between items-center">
            <div>Last Modification:</div><div>{{formatDate(item.modifyTime)}}</div>
          </div>
      </div>

      <div class="w-full flex flex-row justify-center items-center">
        <div class="font-bold">Access Control</div>
      </div>

      <div class="flex flex-col justify-between items-center text-base">
        <div class="flex flex-row w-full">
          <div class="text-center w-1/4">Type</div>
          <div class="text-center w-1/4">Read</div>
          <div class="text-center w-1/4">Write</div>
          <div class="text-center w-1/4">Execute</div>
        </div>
        <div class="flex flex-row justify-between items-center w-full text-base" v-for="(right, index) in item.rights" :key="index">
          <div class="text-center w-1/4">{{index}}</div>
          <div class="text-center w-1/4"><input type="checkbox" :checked="right[0] === 'r'" @click="togglePermission('r', index)"></div>
          <div class="text-center w-1/4"><input type="checkbox" :checked="right[1] === 'w'" @click="togglePermission('w', index)"></div>
          <div class="text-center w-1/4"><input type="checkbox" :checked="right[2] === 'x'" @click="togglePermission('x', index)"></div>
        </div>
      </div>

      <div class="flex flex-row justify-around items-center text-base mt-4">
        <button class="bg-graybuttonludus px-2 py-1 rounded" @click="closeModal()">
          Close
        </button>
        <button class="bg-greenbuttonludus px-2 py-1 rounded" @click="updateAccess()">
          Update
        </button>
      </div>
    </modal>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
import Modal from './partials/Modal'
export default {
  name: 'FileProperties',
  components: {
    Modal
  },
  props: [
    'item',
    'path'
  ],
  data () {
    return {
      displayItem: this.item,
      permissions: '',
      permissionOrderMap: {
        r: 0,
        w: 1,
        x: 2
      },
      binary: '',
      octal: '',
      error: ''
    }
  },
  methods: {
    ...mapGetters([
      'getConnectionId'
    ]),
    closeModal () {
      this.$emit('closeModal')
    },
    formatDate (linuxTime) {
      return new Date(linuxTime).toLocaleString('en-GB')
    },
    updateAccess () {
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/updateRights`, {
        path: this.path,
        file: this.item.name,
        mode: this.octal
      },
      {
        headers: {
          'connection-id': this.getConnectionId()
        }
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data.error) {
              this.error = response.data.error
            } else {
              this.$emit('closeModal')
            }
          }
        })
        .catch(error => {
          this.error = error.message
        })
    },
    togglePermission (type, index) {
      let itemArray = this.item.rights[index].split('')
      let permissionIndex = this.permissionOrderMap[type]
      if (itemArray[permissionIndex] === type) {
        itemArray[permissionIndex] = '-'
      } else {
        itemArray[permissionIndex] = type
      }
      this.item.rights[index] = itemArray.join('')
      this.updatePermissionsFormats()
    },
    toBinary () {
      let converted = ''
      this.permissions.split('').forEach(element => {
        converted += element === '-' ? '0' : '1'
      })
      return converted
    },
    toOctal () {
      return parseInt(this.toBinary(), 2).toString(8)
    },
    getPermissions () {
      let permissionsGroups = [this.item.rights.user, this.item.rights.group, this.item.rights.other]
      let permissionString = ''
      permissionsGroups.forEach(group => {
        permissionString += group.indexOf('r') !== -1 ? 'r' : '-'
        permissionString += group.indexOf('w') !== -1 ? 'w' : '-'
        permissionString += group.indexOf('x') !== -1 ? 'x' : '-'
      })
      return permissionString
    },
    updatePermissionsFormats () {
      this.permissions = this.getPermissions()
      this.binary = this.toBinary()
      this.octal = this.toOctal()
    }
  },
  mounted () {
    this.updatePermissionsFormats()
  }
}
</script>

<style scoped>
</style>
