<template>

  <div class="flex justify-center items-center">
    <div class="bg-bluewindowludus p-4 rounded-lg">
        <div class="text-white" v-if="isLoggedIn">
          <h1 class="text-center">Welcome to LudusCristaltec Remote Assistant</h1>
          <p class="text-center">Please follow the configuration guide to setup your router device</p>
        </div>
        <div class="w-full max-w-xs" v-else>
          <LudusAuth/>
        </div>
    </div>
  </div>

</template>

<script>

import { mapState, mapActions } from 'vuex'
import {LudusAuth} from '@ludus-npm/vue-auth'

export default {
  name: 'Home',
  components:{
    LudusAuth
  },
  data () {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    ...mapState([
      'isLoggedIn',
      'loginError'
    ])
  },
  methods: {
    ...mapActions([
      'doLogin',
      'doDismissError'
    ]),
    submitLogin () {
      this.doLogin({
        email: this.email,
        password: this.password
      })
    }
  }
}
</script>

<style scoped>

</style>
