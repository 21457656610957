<template>
  <div id="app" class="h-screen flex flex-col">

    <remote-assistant-navbar></remote-assistant-navbar>

    <router-view class="flex-grow p-8 text-2xl bg-repeat bg-bluebgludus h-full"/>

    <remote-assistant-footer></remote-assistant-footer>
  </div>
</template>

<script>

import navbar from './Navbar.vue'
import footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    remoteAssistantNavbar: navbar,
    remoteAssistantFooter: footer
  }
}
</script>

<style>
</style>
