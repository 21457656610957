<template>
  <div class="flex justify-center items-center">
    <div class="flex flex-col justify-start bg-bluewindowludus p-4 rounded-lg w-1/2">
      <remote-assistant-router-setup></remote-assistant-router-setup>
      <remote-assistant-usage></remote-assistant-usage>
    </div>
  </div>
</template>
<script>

import RouterSetup from '../components/RouterSetup'
import Usage from '../components/Usage'

export default {
  name: 'Guide',
  components: {
    remoteAssistantRouterSetup: RouterSetup,
    remoteAssistantUsage: Usage
  }
}
</script>

<style scoped>

</style>
