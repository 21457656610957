<template>
  <div class="flex justify-center items-center">
    <div class="flex flex-col bg-bluewindowludus p-4 rounded w-1/2">
    <br>
    <p v-if="!connected"> No connection to backend server, refresh when status is OK</p>
    <p v-if="error"> {{ error }}</p>
    <p v-if="getRole === 'admin' && destination === 'admin'">RUNNING ON ADMIN MODE</p>
    <div id="terminal"></div>
    </div>
  </div>
</template>
<script>
import { Terminal } from 'xterm'
import { AttachAddon } from 'xterm-addon-attach'
import { FitAddon } from 'xterm-addon-fit'
import { mapGetters } from 'vuex'

export default {
  name: 'Console',
  props: {
    destination: {
      type: String
    },
    user: {
      type: String,
      default: 'root'
    }
  },
  data () {
    return {
      term: null,
      terminalSocket: null,
      connected: null,
      fitAddon: null,
      error: null
    }
  },
  methods: {
    runRealTerminal () {
      this.connected = true
      this.fitAddon.fit()
      this.term.writeln('LudusCristaltec Remote Shell')
      if (this.ipValidator(this.destination)) {
        this.term.writeln('Connecting to the remote machine...')
        let sshUser = this.user ? this.user + '@' : ''
        let connectionCommand = 'ssh ' + sshUser + this.destination.split(':')[0] + ' -p ' + this.destination.split(':')[1] + '\r'
        this.terminalSocket.send(connectionCommand)
      } else if (this.destination === 'admin' && this.getUserType === 'admin') {
        this.term.writeln('Running as admin')
        this.terminalSocket.send('Running as admin\r')
      } else {
        this.terminalSocket.close()
        this.term.writeln('Incorrect parameters!')
      }
    },
    errorRealTerminal (error) {
      this.error = error
    },
    closeRealTerminal () {
      this.connected = false
    },
    ipValidator (ip) {
      let splittedIp = ip.split(':')
      if (splittedIp.length !== 2) {
        return false
      }
      return Number.isInteger(parseInt(splittedIp[1]))
    }
  },
  computed: {
    ...mapGetters([
      'getRole'
    ])
  },
  mounted () {
    this.term = new Terminal({
      cursorBlink: true,
      cursorStyle: 'bar',
      rightClickSelectsWord: true,
      rendererType: 'canvas',
      theme: {
        background: 'black',
        selection: 'red'
      },
      rows: 40,
      cols: 140
    })

    this.terminalSocket = new WebSocket(`${process.env.VUE_APP_SOCKET_ENDPOINT}`)
    this.terminalSocket.onopen = this.runRealTerminal
    this.terminalSocket.onclose = this.closeRealTerminal
    this.terminalSocket.onerror = this.errorRealTerminal
    this.fitAddon = new FitAddon()
    this.term.loadAddon(new AttachAddon(this.terminalSocket))
    this.term.loadAddon(this.fitAddon)
    this.term._initialized = true
    this.term.open(document.getElementById('terminal'))
  },
  beforeDestroy () {
    this.term.dispose()
    this.terminalSocket.close()
  }
}
</script>
<style>
  @import '../../node_modules/xterm/css/xterm.css';
</style>
