<template>
  <modal :width="'w-1/6'">
    <div class="w-full flex flex-row justify-center items-center">
      <h3 class="font-bold">Move Files</h3>
    </div>
    <div class="flex flex-col">
      <div v-if="error"> {{error}}</div>
      <div>Move: {{filesNames}}</div>
      <div>From: {{originalPath}}</div>
      <div>To: <input v-model="destinationPath"/></div>
    </div>
    <div class="flex flex-row justify-around items-center text-base mt-4">
      <button class="bg-graybuttonludus px-2 py-1 rounded" @click="$emit('close')">
        Close
      </button>
      <button class="bg-greenbuttonludus px-2 py-1 rounded" @click="move">
        Move
      </button>
    </div>
  </modal>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
import Modal from './partials/Modal'
export default {
  name: 'FileMover',
  components:{
    Modal
  },
  props: [
    'originalPath',
    'selectedFiles'
  ],
  data () {
    return {
      destinationPath: this.originalPath,
      error: ''
    }
  },
  computed: {
    filesNames () {
      return this.selectedFiles.map((file) => file.name).join(', ')
    }
  },
  methods: {
    ...mapGetters([
      'getConnectionId'
    ]),
    move () {
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/move`, {
        sourcePath: this.originalPath,
        destinationPath: this.destinationPath,
        filenames: this.selectedFiles.map(file => file.name)
      },
      {
        headers: {
          'connection-id': this.getConnectionId()
        }
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data.error) {
              this.error = response.data.error
            } else {
              this.$emit('close')
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
</style>
