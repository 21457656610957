<template>
  <modal :width="'w-1/5'">
    <div class="w-full flex flex-row justify-center items-center ">
      <h3 class="font-bold" v-if="type === 'UPLOADFILE'">Upload File</h3>
      <h3 class="font-bold" v-if="type === 'NEWFILE'">New File</h3>
    </div>
    <div class="flex flex-col justify-start text-base">
      <p>Path: {{destinationPath}}</p>
      <label class="flex flex-col items-center px-4 py-6 bg-bluenavbarludus text-white rounded-lg shadow-lg tracking-wide uppercase cursor-pointer mt-2" v-if="type === 'UPLOADFILE'">
        <svg class="w-4 h-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
        <span class="mt-2 text-base leading-normal">Select a file</span>
        <input type='file' class="hidden" id="file" ref="file" v-on:change="handleFileUpload()" />
      </label>

      <input class="text-black" type="text" v-model="newFileName" v-if="type === 'NEWFILE'" />
    </div>
    <div class="flex flex-row justify-around items-center text-base mt-4">
      <button class="bg-graybuttonludus px-2 py-1 rounded" @click="$emit('close')">
        Close
      </button>
      <button class="bg-greenbuttonludus px-2 py-1 rounded" @click="submitFile()" v-if="type === 'UPLOADFILE'">
        Upload
      </button>
      <button class="bg-greenbuttonludus px-2 py-1 rounded" @click="createFile()" v-if="type === 'NEWFILE'">
        Create
      </button>
    </div>
  </modal>
</template>

<script>

import { mapGetters } from 'vuex'
import axios from 'axios'
import Modal from './partials/Modal'
export default {
  name: 'FileUpload',
  components: {
    Modal
  },
  props: [
    'destinationPath',
    'type'
  ],
  data () {
    return {
      file: '',
      newFileName: ''
    }
  },
  methods: {
    ...mapGetters([
      'getConnectionId'
    ]),
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
    },
    submitFile () {
      let formData = new FormData()
      formData.append('file', this.file)
      formData.append('path', this.destinationPath)

      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/upload`,
        formData,
        {
          headers: {
            'connection-id': this.getConnectionId(),
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.$emit('close', 'refresh')
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    createFile () {
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/ftp/newFile`,
        {
          filename: this.newFileName,
          path: this.destinationPath
        },
        {
          headers: {
            'connection-id': this.getConnectionId()
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.$emit('close', 'refresh')
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
</style>
