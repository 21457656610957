<template @keyup.esc="closeModal">
  <modal :width="'w-1/5'">
    <div class="w-full flex flex-row justify-center items-center">
      <div class="font-bold"> Checksum Information</div>
    </div>

    <div class="flex flex-row justify-between items-center">
      <div class="text-left whitespace-pre text-base" v-html="output"></div>

      <popper
        v-if="checksumValue && checksumFile && checksumType"
        trigger="clickToOpen"
        :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0,10px' } }
              }"
      >
        <div class="popper">
          Copied!
        </div>
        <button class="bg-graybuttonludus px-2 py-1 rounded" slot="reference"
                v-clipboard:copy="output">
          <font-awesome-icon class="alt icon" icon="copy"/>
        </button>
      </popper>
    </div>

    <div class="flex flex-row justify-end items-center text-base mt-4">
      <button class="bg-graybuttonludus  px-2 py-1 rounded" @click="closeModal()">
        Close
      </button>
    </div>

  </modal>
</template>

<script>
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
import Modal from './partials/Modal'
export default {
  name: 'ChecksumDisplay',
  props: [
    'checksumType',
    'checksumValue',
    'checksumFile'
  ],
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    convertBytesToHumanReadable (size) {
      if (size === 0) {
        return ''
      } else {
        let i = Math.floor(Math.log(size) / Math.log(1024))
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KiB', 'MiB', 'GiB', 'TiB'][i]
      }
    }
  },
  computed: {
    output () {
      return `Name ${this.checksumFile.name}\nSize ${this.checksumFile.size} bytes (${this.convertBytesToHumanReadable(this.checksumFile.size)})\n${this.checksumType} ${this.checksumValue}`
    }
  },
  components: {
    popper: Popper,
    Modal
  }
}
</script>

<style scoped>
</style>
