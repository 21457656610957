<template>
  <nav class="flex flex-row items-center bg-bluenavbarludus justify-between p-2">
      <router-link class="flex items-center text-white font-bold text-xl" tag="button" to="/">
          LudusCristaltec Reverse Assistant
      </router-link>

      <div class="flex justify-between items-center" v-if="loggedIn === true">
        <div class="text-white pr-2">Hello {{ getName }}</div>
        <div class="pr-2" :class="color">{{online? 'Connected' : 'Disconnected'}}: <font-awesome-icon class="icon alt" :style="{color: color}" :icon="online? 'toggle-on' : 'toggle-off'" ></font-awesome-icon></div>
      </div>



      <div class="flex flex-row items-center justify-between">
        <div class="flex flex-row text-sm justify-center" v-if="loggedIn === true">
          <router-link class="block sm:inline-block rounded text-white bg-graybuttonludus rounded py-1 px-4 mr-2 font-bold" tag="button" to="/users" v-if="getRole === 'admin'">Users</router-link>
          <router-link class="block sm:inline-block rounded text-white bg-graybuttonludus rounded py-1 px-4 mr-2 font-bold" tag="button" to="/api-clients" v-if="getRole === 'admin'">Clients</router-link>
          <router-link class="block sm:inline-block rounded text-white bg-graybuttonludus rounded py-1 px-4 mr-2 font-bold" tag="button" to="/guide">Guide</router-link>
          <router-link class="block sm:inline-block rounded text-white bg-graybuttonludus rounded py-1 px-4 mr-2 font-bold" tag="button" to="/routers">Routers</router-link>
          <router-link class="block sm:inline-block rounded text-white bg-graybuttonludus rounded py-1 px-4 mr-2 font-bold" tag="button" to="/tunnels">Tunnels</router-link>
          <router-link class="block sm:inline-block rounded text-white bg-graybuttonludus rounded py-1 px-4 mr-2 font-bold" tag="button" to="/logs" v-if="getRole === 'admin'">Logs</router-link>
        </div>

        <div v-if="loggedIn !==true">
          <router-link
            class="inline-block text-sm px-4 py-2 leading-none rounded text-white bg-graybuttonludus md:mt-0 font-bold focus:outline-none"
            tag="button" to="/"
          >
            Login
          </router-link>
        </div>
        <div v-else>
          <button @click="submitLogout"
                  class="inline-block text-sm px-4 py-2 leading-none rounded text-white bg-graybuttonludus md:mt-0 font-bold focus:outline-none"
          >
            Logout
          </button>
        </div>

      </div>

  </nav>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
export default {
  name: 'Navbar',
  data () {
    return {
      online: false,
      pollingHealthcheck: {},
      userMenu: false,
      closeMenuTimer: null
    }
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'getName',
      'getRole'
    ]),
    color () {
      return this.online ? {'text-greenbuttonludus': true} : {'text-redbuttonludus': true}
    }
  },
  methods: {
    ...mapActions([
      'doLogout'
    ]),
    closeMenuTimerSetTimer(){
      this.closeMenuTimer = setTimeout(this.closeUserMenu, 500)
    },
    submitLogout () {
      this.doLogout()
      this.$router.push('/')
    },
    backendCheck () {
      axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/healthcheck`)
        .then(() => {
          this.online = true
        })
        .catch(error => {
          console.log(error)
          this.online = false
        })
    }
  },
  mounted () {
    this.backendCheck()
    this.pollingHealthcheck = setInterval(this.backendCheck, 5000)
  },
  beforeDestroy () {
    clearInterval(this.pollingHealthcheck)
  }
}
</script>

<style scoped>

</style>
